import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import SidebarData from '../sidebars/sidebardata/SidebarData';

const BreadCrumbs = () => {
  const location = useLocation();
  const firstUrl = location.pathname.split('/')[1];
  const secondUrl = location.pathname.split('/')[2];
  const { user: currentUser } = useSelector((state) => state.user);
  // console.log(firstUrl, secondUrl);
  return (
    <>
      {/* <h4 className="text-capitalize">{secondUrl ? `${secondUrl}` : `${firstUrl}`}</h4> */}
      <Breadcrumb>
        {currentUser.role == '1' ? (
          <BreadcrumbItem
            to="/Dashboards/AdminDashboard"
            tag={Link}
            className="text-decoration-none link-info fw-normal"
          >
            Home
          </BreadcrumbItem>
        ) : currentUser.role == '6' ? (
          <BreadcrumbItem
            to="/Dashboards/RetailerDashboard"
            tag={Link}
            className="text-decoration-none link-info fw-normal"
          >
            Home
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem to="#" tag={Link} className="text-decoration-none link-info fw-normal">
            Home
          </BreadcrumbItem>
        )}
        {firstUrl ? <BreadcrumbItem active>{firstUrl}</BreadcrumbItem> : ''}
        {secondUrl ? <BreadcrumbItem active>{secondUrl}</BreadcrumbItem> : ''}
      </Breadcrumb>
    </>
  );
};

export default BreadCrumbs;
