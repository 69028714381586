/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useMemo } from "react";
import { useRoutes, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Themeroutes from "./routes/Router";
import ThemeSelector from "./layouts/theme/ThemeSelector";
// import Loader from './layouts/loader/Loader';
import useDynamicRoutes from "./routes/dynamicRoutes";
import _ from "lodash";
import Loadable from "./layouts/loader/Loadable";
import {
  templateDefault,
  templateOne,
  templateThree,
  templateTwo,
} from "./components/common/constant";
import { ThemeProvider } from "./contextAPI/themeAPI";
// import { fetchData } from '../../slices/authSlice/dataSlice';
// import { fetchUserControlData } from '../../slices/authSlice/getControlSlice';

const Loader = Loadable(lazy(() => import("./layouts/loader/Loader")));
// const Loader = Loadable(lazy(() => import("./components/common/loader")));
//Theme 1 or default them
const Home = Loadable(lazy(() => import("./views/Web/Home")));
const About = Loadable(lazy(() => import("./views/Web/AboutUs")));
const Services = Loadable(lazy(() => import("./views/Web/Services")));
const OurPartner = Loadable(lazy(() => import("./views/Web/OurPartner")));
const Contact = Loadable(lazy(() => import("./views/Web/Contact")));
const TermsAndCondition = Loadable(
  lazy(() => import("./views/Web/TermsAndCondition"))
);
const PrivacyPolicy = Loadable(lazy(() => import("./views/Web/PrivacyPolicy")));
const RefundPolicy = Loadable(lazy(() => import("./views/Web/RefundPolicy")));

//Theme 2
const HomeOne = Loadable(lazy(() => import("./views/Web/Theme-1/Home")));
const AboutOne = Loadable(lazy(() => import("./views/Web/Theme-1/AboutUs")));
const ServiceOne = Loadable(lazy(() => import("./views/Web/Theme-1/Services")));
const OurPartnerOne = Loadable(
  lazy(() => import("./views/Web/Theme-1/OurPartner"))
);
const ContactOne = Loadable(lazy(() => import("./views/Web/Theme-1/Contact")));
const TermOne = Loadable(
  lazy(() => import("./views/Web/Theme-1/TermsAndCondition"))
);
const PrivacyOne = Loadable(
  lazy(() => import("./views/Web/Theme-1/PrivacyPolicy"))
);
const RefundOne = Loadable(
  lazy(() => import("./views/Web/Theme-1/RefundPolicy"))
);

//Theme 3
const HomeTwo = Loadable(lazy(() => import("./views/Web/Theme-2/Home")));
const AboutTwo = Loadable(lazy(() => import("./views/Web/Theme-2/AboutUs")));
const ServiceTwo = Loadable(lazy(() => import("./views/Web/Theme-2/Services")));
const OurPartnerTwo = Loadable(
  lazy(() => import("./views/Web/Theme-2/OurPartner"))
);
const ContactTwo = Loadable(lazy(() => import("./views/Web/Theme-2/Contact")));
const TermTwo = Loadable(
  lazy(() => import("./views/Web/Theme-2/TermsAndCondition"))
);
const PrivacyTwo = Loadable(
  lazy(() => import("./views/Web/Theme-2/PrivacyPolicy"))
);
const RefundTwo = Loadable(
  lazy(() => import("./views/Web/Theme-2/RefundPolicy"))
);

const App = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const dispatch = useDispatch();
  const domainData = useDynamicRoutes();
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  // const { user: currentUser } = useSelector((state) => state.user);
  // const { token: Usertoken } = useSelector((state) => state.user);
  // const { user: currentUser } = useSelector((state) => state.user);
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = () => {
    navigate.go(1);
  };
  // useEffect(() => {
  //   dispatch(fetchUserData({ Usertoken, currentUser }));
  // }, [Usertoken]);
  setInterval(() => {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    if (hour === 0 && minute === 0) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.clear();
    }
  }, 30 * 1000);

  // console.log("navigate---", location, window.location, domainData);

  const newRouteList = useMemo(() => {
    let list = _.cloneDeep(Themeroutes);
    if (!_.isEmpty(list)) {
      list = _.map(list, (val) => {
        if (val.key === 1 && !_.isEmpty(val.children)) {
          // val.children = [
          //   ...val.children,
          //   { path: "/", name: "Landing", element: <Loader /> },
          // ];
          // console.log("domainData---", domainData);
          if (!_.isEmpty(domainData)) {
            val.children = _.filter(val.children, (l) => l.name !== "Landing");

            if (_.includes([templateDefault, templateOne], domainData.template_name)) {
              val.children = [
                ...val.children,
                { path: "/", name: "home", element: <Home /> },
                { path: "about", element: <About /> },
                { path: "services", element: <Services /> },
                { path: "OurPartner", element: <OurPartner /> },
                { path: "contact", element: <Contact /> },
                { path: "termsandcondition", element: <TermsAndCondition /> },
                { path: "privacypolicy", element: <PrivacyPolicy /> },
                { path: "refundpolicy", element: <RefundPolicy /> },
                { path: "*", element: <Navigate to="/404" /> },
              ];
            }
            if (domainData.template_name === templateTwo) {
              val.children = [
                ...val.children,
                { path: "/", name: "home", element: <HomeOne /> },
                { path: "about", element: <AboutOne /> },
                { path: "services", element: <ServiceOne /> },
                { path: "OurPartner", element: <OurPartnerOne /> },
                { path: "contact", element: <ContactOne /> },
                { path: "termsandcondition", element: <TermOne /> },
                { path: "privacypolicy", element: <PrivacyOne /> },
                { path: "refundpolicy", element: <RefundOne /> },
                { path: "*", element: <Navigate to="/404" /> },
              ];
            }
            if (domainData.template_name === templateThree) {
              val.children = [
                ...val.children,
                { path: "/", name: "home", element: <HomeTwo /> }, //name: "home"
                { path: "about", element: <AboutTwo /> },
                { path: "services", element: <ServiceTwo /> },
                { path: "OurPartner", element: <OurPartnerTwo /> },
                { path: "contact", element: <ContactTwo /> },
                { path: "termsandcondition", element: <TermTwo /> },
                { path: "privacypolicy", element: <PrivacyTwo /> },
                { path: "refundpolicy", element: <RefundTwo /> },
                { path: "*", element: <Navigate to="/404" /> },
              ];
            }
          } else {
            val.children = [
              ...val.children,
              { path: "*", name: "Landing", element: <Loader /> },
              { path: "/", name: "home", element: <Loader /> },
              // { path: "about", element: <About /> },
              // { path: "services", element: <Services /> },
              // { path: "OurPartner", element: <OurPartner /> },
              // { path: "contact", element: <Contact /> },
              // { path: "termsandcondition", element: <TermsAndCondition /> },
              // { path: "privacypolicy", element: <PrivacyPolicy /> },
              // { path: "refundpolicy", element: <RefundPolicy /> },
            ];
          }
        }
        return val;
      });
    }
    return list;
  }, [Themeroutes, domainData]);

  const routing = useRoutes(newRouteList);

  // useEffect(() => {
  //   // Event listener to handle the beforeunload event
  //   const handleBeforeUnload = (event) => {
  //     console.log(event);
  //     if (event.type === 'beforeunload') {
  //       // Perform any cleanup tasks, like clearing location storage
  //       sessionStorage.clear();
  //       // The message below is optional, but it will prompt the user with a confirmation dialog
  //       event.returnValue = 'Are you sure you want to leave?';
  //     }
  //   };
  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider>
        <div
          className={`${direction ? "rtl" : "ltr"} ${isMode ? "dark" : ""}`}
          dir={direction ? "rtl" : "ltr"}
        >
          <ThemeSelector />
          {routing}
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
