import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../Utility/APIUtils';
import _ from 'lodash';

const initialState = {
  // creditAmount
  creditAmountSucess: false,

  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const createRetailer = createEncryptedAsyncThunk(
  'manageRoleDistributor/createRetailer',
  'account/CreateUser',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      nameTitle: params.title,
      username: '',
      password: '',
      tpin: '',
      emailAddress: params.body.emaiID,
      firstName: params.body.firstName,
      middleName: params.body.middleName,
      lastName: params.body.lastName,
      primaryPhone: params.body.contactNumber,
      primaryPhoneType: 0,
      secondaryPhone: params.body.altContactNumber,
      secondaryPhoneType: 0,
      role: params.roleId,
      senior_ID: params.currentUser.userId,
      business_Name: params.body.businessName,
      business_Add: params.businessAddress,
      business_Contact_No: params.body.businessContact,
      business_Type: 'string',
      state_ID: params.selectState.value,
      city_ID: params.selectCity.value,
      pincode: params.body.pincode,
      area: 'string',
      landMark: params.body.landmark,
      address: params.address,
      created_By: 0,
      // plan_id: 0,
      plan_id: !_.isEmpty(params.planList)
        ? _.first(params.planList).value || 0
        : 0,
      whatsapp_no: params.body.whatupNo,
      block_add: params.body.block,
      gram_panchayat: params.body.grampanchayat,
      post_office: params.body.postoffice,
      police_thana: params.body.policethana,
      gram: params.body.gram,
      other: '',
      dob: params.body.DateOfBirth,
      custom_Attributes: {
        ip: params.location.ip,
        deviceId: '',
        deviceType: '',
        lattitude: params.location.lattitude,
        longitude: params.location.longitude,
      },
    }),
  },
);
// export const getAllRetailer = createEncryptedAsyncThunk(
//   'manageRoleDistributor/getAllRetailer',
//   (params) =>
//     `account/GetUserDetailsByRole?userID=${params.currentUser.userId}&role=${params.roleId}`,
//   {
//     method: 'GET',
//   },
// );
export const getAllRetailer = createEncryptedAsyncThunk(
  'manageRoleDistributor/getAllRetailer',
  'account/GetUserDetailsByRole',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: 0,
      status: 3,
      senior_ID: 0,
      apiUser: 3,
    }),
  },
);
export const manageCreditDebitToUserDistributor = createEncryptedAsyncThunk(
  'manageRoleDistributor/manageCreditDebitToUserDistributor',
  'Wallet/AddWalletTopup',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      transaction_id: '',
      from_user_id: `${params.currentUser.userId}`,
      to_user_id: params.selectRetailer.value,
      trans_type: params.transType.value,
      topup_mode: 1,
      amount: Number(params.amount),
      narration: params.narration || '',
      created_by: params.currentUser.userId,
    }),
  },
);
export const manageRoleDistributor = createSlice({
  name: 'manageRoleDistributor',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = manageRoleDistributor.actions;
export default manageRoleDistributor.reducer;
