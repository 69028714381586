import React from "react";
// import { Link } from 'react-router-dom';
import Mainlogo from "../../assets/images/logos/logo.png";
import { useTheme } from "../../contextAPI/themeAPI";
import { useSelector } from "react-redux";

const AuthLogo = () => {
  const { user: currentUser } = useSelector((state) => state.user);
  const {dashboardLogo} = useTheme()

  return (
    <div className="p-0 d-flex  justify-content-center gap-2 mt-0">
      <img src={currentUser.role === "3" ? dashboardLogo : Mainlogo} alt="profile" width="120px" height="auto"></img>
    </div>
  );
};

export default AuthLogo;
