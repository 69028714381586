/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import { Lock, User } from 'react-feather';
import userImage from '../../assets/images/users/user2.jpg';
import {
  userDeleteFailed,
  userDeleteInitial,
  getProfileDetails,
} from '../../slices/retailer/UserAppSlice/UserDetailsSlice';

const ProfileDD = () => {
  // const { user: currentUser } = useSelector((state) => state.user);
  // const userData = useSelector((state) => state.userData.user);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const [, setProData] = useState([]);
  const dispatch = useDispatch();
 
  // const renderElement = () => {
  // };

  useEffect(() => {
    dispatch(getProfileDetails({ currentUser, Usertoken }))
      .unwrap()
      .then((res) => {
        if (res.status.type === 'success') {
          setProData(res.data[0]);
          //console.log('res data:', res)
          dispatch(userDeleteInitial());
        } else {
          dispatch(userDeleteFailed(res));
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  return (
    <div>
      <div className="d-flex gap-2 p-2 text-white rounded-top bg-info pt-2 align-items-center">
        <img src={userImage} alt="user" width="30" className="rounded-circle" />

        <span>
          <h5 className="mt-2">{currentUser.firstName}</h5>
          {/*<small className="fs-6 opacity-60 text-dark">{currentUser.RoleName}  </small> */}
        </span>
      </div>

      {currentUser.role === '1' ? (
        <>
        <Link to="/Profile/ProfileUpdate" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
        
        <Link to="/Admin/ChangesPasswordAd" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <Lock size={20} />
            &nbsp; Change Password
          </DropdownItem>
        </Link>
        </>
      ) : null}
      {currentUser.role === '3' ? (
        <Link to="/Profile/ProfileUpdateMD" className="text-decoration-none ">
          <DropdownItem className="px-2 py-2 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
      ) : null}
      {currentUser.role === '2' ? (
        <Link to="/Profile/UpdateProfileBusiness" className="text-decoration-none ">
          <DropdownItem className="px-2 py-2 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
      ) : null}
      {currentUser.role === '4' ? (
        <Link to="/Profile/UpdateProfileSD" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
      ) : null}

      {currentUser.role === '5' ? (
        <Link to="/Profile/UpdateProfileDistributor" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
      ) : null}

      {currentUser.role === '6' ? (
        <>
        <Link to="/Profile/UpdateProfileAgent" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <User size={20} />
            &nbsp; My Profile
          </DropdownItem>
        </Link>
        
        {/* <Link to="/Agent/ChangePassword" className="text-decoration-none ">
          <DropdownItem className="px-3 py-3 d-flex">
            <User size={20} />
            &nbsp; Change Password
          </DropdownItem>
        </Link> */}
        </>
      ) : null}

      <DropdownItem divider />
    </div>
  );
};

export default ProfileDD;
