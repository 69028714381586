import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Nav, NavLink, NavItem, Collapse } from "reactstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import HorizontalSidebarData from "../sidebardata/HorizontalSidebarData";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import { logout } from "../../../slices/authSlice/userSilce";
import rlogo from "../../../assets/images/logos/logo.png";
import { useTheme } from "../../../contextAPI/themeAPI";
import { templateDefault } from "../../../components/common/constant";

/* eslint-disable */

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getActive = document.getElementsByClassName("activeLink");
  const [collapsed, setCollapsed] = React.useState(false);
  const [collapsed0, setCollapsed0] = React.useState(false);
  const [collapsed2, setCollapsed2] = React.useState(false);
  const [collapsed3, setCollapsed3] = React.useState(false);
  const [collapsed4, setCollapsed4] = React.useState(false);
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  // const controlData = useSelector((state) => state.controlData.items);
  // const userData = useSelector((state) => state.userData.user);
  // const baseB4 = 'data:image/jpeg;base64,';
  const {dashboardMenuColor, templateName} = useTheme()
  
  const menuConfig = {}
  if (templateName !== templateDefault) {
    menuConfig.style = {color: dashboardMenuColor}
  }

  const setting = [
    {
      title: "Set TPIN",
      href: "/Agent/SetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Reset TPIN",
      href: "/Agent/ResetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Change Password",
      href: "/Agent/ChangePassword",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "Raise Complaint",
      href: "/Agent/ComplaintListAgent",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
  ];
  const support = [
    {
      title: "Complaint & Support",
      href: "/Retailer/ComplaintsView",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
  ];

  const agentReports = [
    // {
    //   title: "Account Status Report",
    //   href: "/Agent/AccountStatusReports",
    //   icon: <Icon.ChevronRight />,
    //   id: 13.0,
    //   collapisble: false,
    // },
    {
      title: "Ledger Reports",
      href: "/Agent/LedgerReport",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "AEPS Ledger Reports",
      href: "/Agent/AEPSLedgerReport",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "Money Transfer Reports",
      href: "/Agent/DMTTransactionReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "Money Transfer Hold Case Reports",
      href: "/Agent/MoneyTransferHoldCases",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "AEPS Transaction Reports",
      href: "/Agent/AEPSTransactionReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "Bill Payments Reports",
      href: "/Agent/BBPSPaymentsReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "Payments Getaway Reports",
      href: "/Agent/PaymentGetwayReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "OutStanding Reports",
      href: "/Agent/OutStandingReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    // {
    //   title: "GST Reports",
    //   href: "/Agent/GSTReports",
    //   icon: <Icon.ChevronRight />,
    //   id: 13.1,
    //   collapisble: false,
    // },
    // {
    //   title: "TDS Reports",
    //   href: "/Agent/TDSReports",
    //   icon: <Icon.ChevronRight />,
    //   id: 13.1,
    //   collapisble: false,
    // },
    {
      title: "TopUp Reports",
      href: "/Agent/TopUpReports",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "Change Status By Txn Id",
      href: "/Agent/AdminChangeStatus",
      icon: <Icon.ChevronRight />,
      id: 10.1,
      collapisble: true,
    },
  ];
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  // const toggle1 = () => {
  //   setCollapsed1(!collapsed1);
  // };
  const toggle2 = () => {
    setCollapsed2(!collapsed2);
  };
  const toggle3 = () => {
    setCollapsed3(!collapsed3);
  };
  const toggle4 = () => {
    setCollapsed4(!collapsed4);
  };
  const toggle0 = () => {
    setCollapsed0(!collapsed0);
  };

  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/login", { replace: true });
    window.location.reload();
  };

  const getUrlPath = location.pathname;
  useEffect(() => {
    if (currentUser.password_Reset) {
      const checkUrl = !_.includes(
        [
          [
            "/Admin/ChangesPasswordAd",
            "/Business/ChangePasswordBD",
            "/MasterDistributor/ChangePasswordMd",
            "/SuperDistributor/ChangePasswordSP",
            "/Distributor/ChangePasswordDistributer",
            "/Agent/ChangePassword",
            "/Employee/ChangePasswordEMP",
          ],
        ],
        getUrlPath
      );
      if (checkUrl) {
        if (currentUser.role == "1") {
          navigate("/Admin/ChangesPasswordAd");
        } else if (currentUser.role == "2") {
          navigate("/Business/ChangePasswordBD");
        } else if (currentUser.role == "3") {
          navigate("/MasterDistributor/ChangePasswordMd");
        } else if (currentUser.role == "4") {
          navigate("/SuperDistributor/ChangePasswordSP");
        } else if (currentUser.role == "5") {
          navigate("/Distributor/ChangePasswordDistributer");
        } else if (currentUser.role == "6") {
          navigate("/Agent/ChangePassword");
        } else if (currentUser.role == "7") {
          navigate("/Employee/ChangePasswordEMP");
        }
      }
    }
  }, [getUrlPath, currentUser]);

  return (
    <div className={`sidebarBox   ${isFixed ? "fixedSidebar" : ""}`}>
      <SimpleBar style={{ height: "100%" }}>
        <div className="mb-4 pb-5 mt-1" loading={loading}>
          {currentUser.role == "6" ? (
            <div className="logo-r-s">
              <img src={rlogo} alt="" style={{ width: "160px" }} />
            </div>
          ) : null}
          <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
            {!currentUser.password_Reset ? (
              currentUser.role === "6" && currentUser.kycStatus ? (
                <>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Dashboards/RetailerDashboard"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Home />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Dashboard</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Beneficiary/CopyBeneficiary" className="gap-2 d-none"> */}
                    <NavLink tag={Link} to="#" className="gap-2 d-none">
                      <span className="sidebarIcon d-flex align-items-center">
                        {" "}
                        <Icon.Copy />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Copy Beneficiary</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/SelfBank/RetailorBankList"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        {" "}
                        <Icon.User />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Agent Self Bank </span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Agent/AEPSPayout"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        {" "}
                        <Icon.Airplay />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>AEPS Payout</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Agent/AEPSToDMTTransfer"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.MousePointer />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>AEPS Wallet to DMT</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={collapsed4 && getActive ? "activeParent" : ""}
                    loading={loading}
                  >
                    <NavLink className="cursor-pointer gap-2" onClick={toggle4}>
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.PhoneCall />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Agent Reports</span>
                          <span className="ms-auto">
                            <i
                              className={`bi fs-8 ${
                                collapsed4
                                  ? "bi-chevron-down"
                                  : "bi-chevron-right"
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </NavLink>
                    <Collapse
                      isOpen={collapsed4}
                      navbar
                      tag="ul"
                      className="subMenu"
                    >
                      {agentReports.map((item) => (
                        <NavItem
                          key={item.title}
                          className={`hide-mini ${
                            location.pathname === item.href ? "activeLink" : ""
                          }`}
                        >
                          <NavLink tag={Link} to={item.href} className="gap-2">
                            <span className="sidebarIcon">{item.icon}</span>
                            <span className="hide-mini">
                              <span>{item.title}</span>
                            </span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Collapse>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Agent/BalanceRequestHistory"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.DollarSign />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Balance Request</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>

                  <NavItem
                    className={collapsed2 && getActive ? "activeParent" : ""}
                    loading={loading}
                  >
                    <NavLink className="cursor-pointer gap-2" onClick={toggle2}>
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Settings />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Setting</span>
                          <span className="ms-auto">
                            <i
                              className={`bi fs-8 ${
                                collapsed2
                                  ? "bi-chevron-down"
                                  : "bi-chevron-right"
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </NavLink>
                    <Collapse
                      isOpen={collapsed2}
                      navbar
                      tag="ul"
                      className="subMenu"
                    >
                      {setting.map((item) => (
                        <NavItem
                          key={item.title}
                          className={`hide-mini ${
                            location.pathname === item.href ? "activeLink" : ""
                          }`}
                        >
                          <NavLink tag={Link} to={item.href} className="gap-2">
                            <span className="sidebarIcon">{item.icon}</span>
                            <span className="hide-mini">
                              <span>{item.title}</span>
                            </span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Collapse>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="#" className="gap-2">
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Hexagon />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Privacy Policy</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="#" className="gap-2">
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Shield />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Terms & Condition</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={handleLogoutClick} className="gap-2">
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.LogOut />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Logout</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={
                      collapsed3 && getActive ? "activeParent d-none" : "d-none"
                    }
                    loading={loading}
                  >
                    <NavLink className="cursor-pointer gap-2" onClick={toggle3}>
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.HelpCircle />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Support</span>
                          <span className="ms-auto">
                            <i
                              className={`bi fs-8 ${
                                collapsed3
                                  ? "bi-chevron-down"
                                  : "bi-chevron-right"
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </NavLink>
                    <Collapse
                      isOpen={collapsed3}
                      navbar
                      tag="ul"
                      className="subMenu"
                    >
                      {support.map((item) => (
                        <NavItem
                          key={item.title}
                          className={`hide-mini ${
                            location.pathname === item.href ? "activeLink" : ""
                          }`}
                        >
                          <NavLink tag={Link} to={item.href} className="gap-2">
                            <span className="sidebarIcon">{item.icon}</span>
                            <span className="hide-mini">
                              <span>{item.title}</span>
                            </span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Collapse>
                  </NavItem>
                </>
              ) : currentUser.role === "5" && currentUser.kycStatus ? (
                <>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Dashboards/DistributorDashboard"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Home />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Dashboard</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Distributor/ViewRetailerDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UserPlus />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Add Agent</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Distributor/ManageCreditDebitDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Clipboard />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Manage Credit/Debit</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Distributor/CreditDetailsDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Clipboard />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Credit Details</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={collapsed4 && getActive ? "activeParent" : ""}
                    loading={loading}
                  >
                    <NavLink className="cursor-pointer gap-2" onClick={toggle4}>
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Distributor Reports</span>
                          <span className="ms-auto">
                            <i
                              className={`bi fs-8 ${
                                collapsed4
                                  ? "bi-chevron-down"
                                  : "bi-chevron-right"
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </NavLink>
                    <Collapse
                      isOpen={collapsed4}
                      navbar
                      tag="ul"
                      className="subMenu"
                    >
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/dashboards/LedgerReportDistributor"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Distributor Ledger</span>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/Distributor/DistributorBusinessReport"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Business Reports</span>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/Distributor/DistributorOutStandingReports"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Outstanding Reports</span>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                        <NavLink
                          tag={Link}
                          to="/Distributor/DirectTopUpReports"
                          className="gap-2"
                        >
                          <span className="sidebarIcon d-flex align-items-center">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini w-100">
                            <div className="d-flex align-items-center">
                              <span>Direct Top Up Report</span>
                            </div>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                        <NavLink
                          tag={Link}
                          to="/dashboards/CommisionReportDistributor"
                          className="gap-2 d-none"
                        >
                          <span className="sidebarIcon d-flex align-items-center">
                            <Icon.List />
                          </span>
                          <span className="hide-mini w-100">
                            <div className="d-flex align-items-center">
                              <span>Commission Report</span>
                            </div>
                          </span>
                        </NavLink>
                      </NavItem>
                    </Collapse>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Distributor/ChangePasswordDistributor" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/Distributor/ChangePasswordDistributer"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Settings />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Change Password</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Bank/DistributorBankList" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/Distributor/DistributorBankList"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UploadCloud />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Self Bank Master</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Distributor/BalanceRequestHistoryDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Send />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Balance Request</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Distributor/TransactionReportAgent" className="gap-2"> */}
                    <NavLink tag={Link} to="#" className="gap-2 d-none">
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Agent Trans Details</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                </>
              ) : currentUser.role === "4" && currentUser.kycStatus ? (
                <>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Dashboards/SuperDistributorDashboard"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Home />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Dashboard</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      // to="/SuperDistributor/ViewDistributor"
                      to="/dashboards/SuperViewDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UserPlus />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Add Distributor</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      // to="/SuperDistributor/ViewDistributor"
                      to="/dashboards/SuperViewRetailer"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UserPlus />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Add Retailer</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/SuperManageCreditDebitDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Clipboard />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Manage Credit/Debit</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/SuperCreditDetailsDistributor"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Clipboard />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Credit Details</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={collapsed4 && getActive ? "activeParent" : ""}
                    loading={loading}
                  >
                    <NavLink className="cursor-pointer gap-2" onClick={toggle4}>
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Reports</span>
                          <span className="ms-auto">
                            <i
                              className={`bi fs-8 ${
                                collapsed4
                                  ? "bi-chevron-down"
                                  : "bi-chevron-right"
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </NavLink>
                    <Collapse
                      isOpen={collapsed4}
                      navbar
                      tag="ul"
                      className="subMenu"
                    >
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/dashboards/LedgerSuperReportDistributor"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Ledger Details</span>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/SuperDistributor/SuperDistributorBusinessReport"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Business Reports</span>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/SuperDistributor/SuperDistributorOutStandingReports"
                          className="gap-2"
                        >
                          <span className="sidebarIcon">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini">
                            <span>Outstanding Reports</span>
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                        <NavLink
                          tag={Link}
                          to="/dashboards/CommisionReportDistributor"
                          className="gap-2 d-none"
                        >
                          <span className="sidebarIcon d-flex align-items-center">
                            <Icon.List />
                          </span>
                          <span className="hide-mini w-100">
                            <div className="d-flex align-items-center">
                              <span>Commission Report</span>
                            </div>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                        <NavLink
                          tag={Link}
                          to="/SuperDistributor/DirectTopUpReport"
                          className="gap-2"
                        >
                          <span className="sidebarIcon d-flex align-items-center">
                            <Icon.ChevronRight />
                          </span>
                          <span className="hide-mini w-100">
                            <div className="d-flex align-items-center">
                              <span>Direct TopUp Report</span>
                            </div>
                          </span>
                        </NavLink>
                      </NavItem>
                    </Collapse>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Reports/SPCommissionReport" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/dashboards/CommisionSuperReportDistributor"
                      className="gap-2 d-none"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Commission Report</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/SuperDistributor/ChangePasswordSP" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/SuperDistributor/ChangePasswordSP"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Settings />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Change Password</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Bank/SPDistributorBankList" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/SuperDistributor/SPDistributorBankList"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UploadCloud />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Self Bank Master</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    {/* <NavLink tag={Link} to="/Funds/FundRequestSuperDistributor" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/SuperDistributor/BalanceRequestHistorySuper"
                      className="gap-2"
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Send />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Balance Request</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                </>
              ) : currentUser.role === "3" && currentUser.kycStatus ? (
                <>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Dashboards/MasterDistributorDashboard"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Home color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Dashboard</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/Dashboards/MasterDistributorWhiteLabel"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                      <i className="bi bi-tags" />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>White Label</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/MasterViewSuperDistributor"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.User color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Super Distributor List</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/MasterViewDistributor"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UserPlus color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Distributor List</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/MasterViewRetailer"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UserCheck color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Retailer List</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/MasterDistributor/APIMasterAccess"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.User color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>API Master</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/dashboards/MasterManageCreditDebitDistributor"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.DollarSign color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Manage Credit/Debit</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/MasterDistributor/MasterCreditDetails"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Activity color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Credit Details</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Reports/SPLedgerReports" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/dashboards/LedgerMasterReportDistributor"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Ledger Details</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Reports/SPCommissionReport" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/dashboards/CommisionSuperReportDistributor"
                      className="gap-2 d-none"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.List color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Commission Report</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/SuperDistributor/ChangePasswordSP" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/MasterDistributor/ChangePasswordMd"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Settings color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Change Password</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Bank/SPDistributorBankList" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/MasterDistributor/MasterBankList"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.UploadCloud color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Self Bank Master</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavLink tag={Link} to="/Funds/FundRequestSuperDistributor" className="gap-2"> */}
                    <NavLink
                      tag={Link}
                      to="/MasterDistributor/BalanceRequestHistoryMaster"
                      className="gap-2"
                      {...menuConfig}
                    >
                      <span className="sidebarIcon d-flex align-items-center">
                        <Icon.Send color={dashboardMenuColor} />
                      </span>
                      <span className="hide-mini w-100">
                        <div className="d-flex align-items-center">
                          <span>Balance Request</span>
                        </div>
                      </span>
                    </NavLink>
                  </NavItem>
                </>
              ) : currentUser.role === "1" ? (
                <>
                  {HorizontalSidebarData.map((navi) => {
                    if (navi.caption) {
                      return (
                        <div
                          className="navCaption text-uppercase mt-4"
                          key={navi.caption}
                        >
                          {navi.caption}
                        </div>
                      );
                    }
                    if (navi.children) {
                      return (
                        <NavSubMenu
                          key={navi.id}
                          icon={navi.icon}
                          title={navi.title}
                          items={navi.children}
                          suffix={navi.suffix}
                          suffixColor={navi.suffixColor}
                          // toggle={() => toggle(navi.id)}
                          // collapsed={collapsed === navi.id}
                          isUrl={currentURL === navi.href}
                        />
                      );
                    }
                    return (
                      <NavItemContainer
                        key={navi.id}
                        //toggle={() => toggle(navi.id)}
                        className={
                          location.pathname === navi.href ? "activeLink" : ""
                        }
                        to={navi.href}
                        title={navi.title}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        icon={navi.icon}
                      />
                    );
                  })}
                </>
              ) : (
                <h3
                  className="text-warning fw-bold"
                  style={{ marginLeft: "1.7rem" }}
                >
                  User KYC is Pending
                </h3>
              )
            ) : (
              <h5
                className="text-warning fw-bold mt-5"
                style={{ marginLeft: "1.7rem" }}
              >
                User Change Password Pending
              </h5>
            )}
          </Nav>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
