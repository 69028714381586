/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormGroup, Col } from "reactstrap";
import {
  credDeleteSuccess,
  creditSelf,
} from "../../../slices/admin/CreditDebit/CreditDebitSlice";
import { fetchData } from "../../../slices/authSlice/dataSlice";
import AdminPageLayout from "../../../layouts/AdminPageLayout";
import SimpleInputField from "../../../components/FormComponent/InputField";

const CreditSelfAdmin = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [creditAmount, setCreditAmount] = useState("");
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);

  const AddMoney = () => {
    setLoading(true);
    dispatch(
      creditSelf({
        currentUser,
        Usertoken,
        creditAmount,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status.type === "success") {
          toast.success("Balance added successfully");
          props.setOpenWalletModal(false)
          dispatch(fetchData({ Usertoken, currentUser }));
          dispatch(credDeleteSuccess());
          setLoading(false);
        } else {
          toast.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((res) => {
        toast.error(res);
        setLoading(false);
      });
  };

  return (
    <AdminPageLayout
      loading={loading}
      isBreadCrumbs={false}
      isCardView={false}
      isPadding={false}
    >
      {/* <Row> */}
      <Col md="12">
        <SimpleInputField
          label="Enter Amount"
          name="toupAmount"
          value={creditAmount}
          onChangeAction={setCreditAmount}
        />
      </Col>
      <Col className="mt-2 text-end">
        <FormGroup>
          <Button
            disabled={loading}
            type="submit"
            color="tab"
            className="mt-1"
            onClick={() => AddMoney()}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm mt-1 me-2"></span>
            )}
            Add Money
          </Button>
        </FormGroup>
      </Col>
      {/* </Row> */}
    </AdminPageLayout>
  );
};

export default CreditSelfAdmin;
