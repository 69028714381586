import React from 'react';
// import loader from '../../assets/images/loader/logo.png';
import './loader.css';

const Loader = () => {
  return (
    <div className="container">
      <div className="busy">
        {/* <img src={loader} alt='loader' /> */}
        {/* <dotlottie-player
          src="https://lottie.host/3f3a3a5f-5b64-41b3-a0b0-f0fad0c86f75/9tMeDYdpVR.json"
          background="transparent"
          speed="1"
          loop
          autoplay
          style={{ height: '50px', width: '150px' }}
        ></dotlottie-player> */}
            <dotlottie-player src="https://lottie.host/b4b87bba-77ca-4688-ac3d-e4c12acd910b/0USnomDkRu.json" background="transparent" speed="1" style={{ height: '200px', width: '200px' }} loop autoplay></dotlottie-player>
      </div>
    </div>
  );
};

export default Loader;
